import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/agriculture.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Scheduling Software for Agriculture & Farming | Camelo"
        metaDescription="Learn how Camelo can help the agricultural industry schedule workers' jobs, record time on the job and track payroll more efficiently."
        header="Easy scheduling software for farmers."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
